import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy-policy"} />
		<Helmet>
			<title>
				DriveNow
			</title>
			<meta name={"description"} content={"DriveNow'da yüksek kaliteli araç kiralama hizmetlerini keşfedin."} />
			<meta property={"og:title"} content={"DriveNow"} />
			<meta property={"og:description"} content={"DriveNow'da yüksek kaliteli araç kiralama hizmetlerini keşfedin."} />
			<link rel={"shortcut icon"} href={"https://kz-wintograce.com/img/537457745.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<meta name={"msapplication-TileImage"} content={"https://kz-wintograce.com/img/537457745.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0">
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline1"
					color="--dark"
					sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Gizlilik Politikası
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					Bu Gizlilik Politikası ("Politika"), kz-wintograce.com ("biz", "bize" veya "bizim") web sitesinin ziyaretçileri ve kullanıcılarının ("siz" veya "sizin") kişisel verilerini nasıl topladığını, kullandığını, aktardığını ve koruduğunu açıklamaktadır. Bu politika, kz-wintograce.com ve ilgili hizmetler ("Hizmetler") için geçerlidir.
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					Hizmetlerimizi kullanarak, bu Politikada açıklanan uygulamaları kabul etmiş olursunuz. Bu Politikayı kabul etmiyorsanız, lütfen Hizmetlerimizi kullanmayın.
				</Text>
				<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
					1. Topladığımız Bilgiler
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					Aşağıdaki tür bilgileri toplayabiliriz:
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					1.1 Kişisel Veriler: Adınız, e-posta adresiniz, posta adresiniz gibi kişisel verilerinizi ve Hizmetlerimizle etkileşimde bulunduğunuzda gönüllü olarak sağladığınız diğer bilgileri toplayabiliriz.
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					1.2 Kullanım Bilgileri: IP adresiniz, tarayıcı türünüz, işletim sisteminiz ve ziyaret ettiğiniz sayfalar gibi Hizmetlerimizle olan etkileşimlerinize ilişkin kişisel olmayan bilgileri toplayabiliriz. Bu bilgileri toplamak için çerezler ve benzer teknolojiler kullanabiliriz.
				</Text>
				<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
					2. Bilgilerinizi Nasıl Kullanıyoruz
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					Bilgilerinizi aşağıdaki amaçlarla kullanıyoruz:
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					2.1 Hizmetlerin Sunulması ve İyileştirilmesi: Bilgilerinizi, Hizmetlerimizi sunmak, sürdürmek ve iyileştirmek için kullanıyoruz, ayrıca taleplerinize ve isteklerinize yanıt veriyoruz.
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					2.2 Analiz: Kullanıcı eğilimlerini ve tercihlerini analiz etmek için analitik araçları kullanıyoruz, bu da Hizmetlerimizi geliştirmemize yardımcı olur.
				</Text>
				<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
					3. Bilgilerinizi Paylaşma
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					Kişisel bilgilerinizi üçüncü taraflara satmıyor, kiralamıyor veya takas etmiyoruz. Ancak, aşağıdaki durumlarda bilgilerinizi paylaşabiliriz:
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					3.1 Yasal Gereksinimler: Bilgilerinizi, kanun, yönetmelik, yasal işlem veya hükümet talebi gerektiriyorsa açıklayabiliriz.
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					3.2 Hakların Korunması: Haklarımızı, gizliliğimizi, güvenliğimizi veya mülkiyetimizi korumak veya herhangi bir kişinin güvenliğini tehdit eden bir acil duruma yanıt vermek gerektiğinde bilgilerinizi açıklayabiliriz.
				</Text>
				<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
					4. Seçenekleriniz
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					Kişisel bilgilerinizle ilgili olarak aşağıdaki seçeneklere sahipsiniz:
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					4.1 Erişim ve Düzeltme: Kişisel bilgilerinize erişebilir ve bunları düzeltebilirsiniz. Bunun için contact@kz-wintograce.com adresinden bizimle iletişime geçebilirsiniz.
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					4.2 Reddetme: Tanıtım e-postalarını almak istemiyorsanız, bu e-postalardaki talimatları izleyerek aboneliğinizi iptal edebilirsiniz.
				</Text>
				<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
					5. Güvenlik
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					Bilgilerinizi korumak için makul idari, teknik ve fiziksel önlemler alıyoruz. Ancak, internet üzerinden yapılan hiçbir aktarım veya elektronik depolama yöntemi tamamen güvenli değildir ve mutlak güvenliği garanti edemeyiz.
				</Text>
				<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
					6. Çocukların Gizliliği
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					Hizmetlerimiz 13 yaşından küçükler için tasarlanmamıştır. 13 yaşından küçük bir çocuktan yanlışlıkla bilgi topladığımızı düşünüyorsanız, lütfen bizimle iletişime geçin, bu bilgileri hemen sileceğiz.
				</Text>
				<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
					7. Gizlilik Politikasındaki Değişiklikler
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Veri işleme uygulamalarımızdan haberdar olmak için bu Politikayı periyodik olarak gözden geçirin.
				</Text>
				<Text as="p" font="--headline2" margin="20px 0 20px 0" text-align="left">
					8. Bize Ulaşın
				</Text>
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
					Bu Gizlilik Politikası veya veri işleme uygulamalarımız hakkında herhangi bir sorunuz, endişeniz veya talebiniz varsa, lütfen{" "}
					<Link href="/contacts">
						bizimle iletişime geçin
					</Link>
					.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});